/* eslint-disable camelcase */
/* eslint-disable max-lines */
/* eslint-disable no-useless-escape */
import { isValidPhoneNumber, parsePhoneNumber, validatePhoneNumberLength } from 'libphonenumber-js/max';
import parseMax from 'libphonenumber-js/max';

export function useStorage() {
    return {
        clear: function() {
            return sessionStorage.clear();
        },
        get: function(key) {
            const storedValue = sessionStorage.getItem(key);

            try {
                const parsed = JSON.parse(storedValue);

                return parsed;
            } catch (e) {
                console.error(e);
            }

            return null;
        },
        key: function(i) {
            return sessionStorage.key(i);
        },
        length: function() {
            return sessionStorage.length;
        },
        remove: function(key) {
            sessionStorage.removeItem(key);
        },
        set: function(key, val) {
            if (val && val !== '') {
                sessionStorage.setItem(key, JSON.stringify(val));
            }
        },
    };
}

export function useValidation() {
    return {
        isValidName: function(value) {
            const regex = new RegExp(/^([^0-9_*~\[\](){}!@#$%^&+=|\\:;"'<,>.?/`:'´]*)$/);

            return (regex.test(value));
        },
        isValidPhone: function(value, mobileOnly = true, locale = 'GR') {
            const validation = {
                isMobilePhone: false,
                isTooLong: validatePhoneNumberLength(value, locale) === 'TOO_LONG',
                isTooShort: validatePhoneNumberLength(value, locale) === 'TOO_SHORT',
                isValidPhoneNumber: isValidPhoneNumber(value, locale) === true,
            };

            if (validation.isValidPhoneNumber) {
                const parsedNumber = parsePhoneNumber(value, locale);

                validation.isMobilePhone = parseMax(parsedNumber.number).getType() === 'MOBILE';
            }

            return mobileOnly ? validation.isMobilePhone : validation.isValidPhoneNumber;
        },
        isValidZip: function(value) {
            const regex = new RegExp('^\\d{3}\\s{0,1}\\d{2}$');
            const maxIntValue = 2147483647;

            return (regex.test(value) && value.replace(/\D/g, '') <= maxIntValue);
        },
    };
}

export function keepAppointmentSlotReservationAlive(isCheckout, cb) {
    const { $, moment, book: { route } } = window;
    const service = useStorage().get('service');

    const currentSlot = {
        date: useStorage().get('date'),
        provider: useStorage().get('provider'),
        session: useStorage().get('session'),
        slot: useStorage().get('slot'),
    };

    if (service && currentSlot && currentSlot.slot && currentSlot.date && currentSlot.provider) {
        currentSlot.sd = moment(currentSlot.date + ' ' + currentSlot.slot);
        currentSlot.slotStartEnd =
                currentSlot.sd.format('H:mm') + '-' + currentSlot.sd.add(service.duration, 'm').format('H:mm');
        currentSlot.slotStartEnd = currentSlot.slotStartEnd.replace(/\s/g, '');

        $.ajax({
            url:
                    route.api.v3.appointment.lock +
                    '?session=' +
                    currentSlot.session +
                    '&slot=' +
                    currentSlot.slotStartEnd +
                    '&date=' +
                    currentSlot.date +
                    '&provider=' +
                    currentSlot.provider +
                    (isCheckout ? '&checkout=true' : ''),
        })
            .done(function(res) {
                if (res && res.success && res.success == 'ok') {
                    // all good
                    if (cb) {cb();}
                } else {
                    console.error('API error. Failed to lock appointment!');
                }
            })
            .fail(function(xhr) {
                console.error('error', xhr);
            });
    }
}

export function handleElementVisibility(element, type) {
    const { $ } = window;

    const callback = {
        hide: function() {
            if (!$(element).hasClass('hidden')) {
                $(element).addClass('hidden');
            }
        },
        show: function() {
            if ($(element).hasClass('hidden')) {
                $(element).removeClass('hidden');
            }
        },
    }[type];

    callback();
}